import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
class PromoSlider extends Component {
	
	render() {
		const { slides } = this.props;
		// console.log(slides);
		return (
			<React.Fragment>

				<section className="home-banner">
				<div className="banner-section">

					<div className=" banner-right">
						<h1>Always make time for your little beauty in your life</h1>
						<p>Pamper Yourself with Luxurious Moments of Beauty and Bliss, Because You Deserve a Little Extra Every Day</p>
						<div className="banner-link">
							<a href="/contact-us" className="btn-fill appoinment">Make an Appointment</a>
							<a href="/products" className="btn-out exlpore">Explore Products</a>
						</div>
					</div>

					<OwlCarousel 
						className="owl-theme"
						nav={true}
						autoplay
						loop={false}
						dots={true}
						items={1} 
						rewind={true}          
						>
						{slides.length === 0 ? (
							<ContentLoader
								height={170}
								width={400}
								speed={1.2}
								primaryColor="#f3f3f3"
								secondaryColor="#ecebeb"
							>
								<rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
								
							</ContentLoader>
						) : (
						
						slides.map(slide =>
							<div>
								
								<div className="slide-item" key={slide.slide_id}>
									<img
										src={slide.slider_image}
										alt={slide.slider_text}
										className={`slider-wrapper__img slider-cust-img `}											
									/>
										
								</div>
								</div>

								
						))}
					</OwlCarousel>	
				</div>
				</section>

				
			</React.Fragment>
		);
	}
}

export default PromoSlider;
