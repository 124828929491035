import React, { Component } from "react";

import { Helmet } from "react-helmet";

class Meta extends Component {
	constructor(props){
		super(props);
		this.state = {
			seotitle: "Nice Beauty | Hair Salon",
			seodescription: "Nice Beauty,Hair Salon.",
			ogtitle: "Nice Beauty | Hair Salon",
			ogdescription: "Nice Beauty | Hair Salon, a name synonymous with grandeur and luxury, is the essential destination to experience a Spa Journey that is a cradle of traditional Arabian customs.",
			twittertitle: "Nice Beauty | Hair Salon",
			twitterdescription: "Nice Beauty | Hair Salon, a name synonymous with grandeur and luxury, is the essential destination to experience a Spa Journey that is a cradle of traditional Arabian customs.",
			ogtype:"website",
			ogurl:window.location.href,
			siteName :"Nice Beauty | Hair Salon",
			twitterSite :"Nice Beauty | Hair Salon",
		}
    }

	
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.seotitle}</title>
                    <meta name="description" content={this.state.seodescription} />
                    <meta property="og:type" content={this.state.ogtype} />
                    <meta property="og:title" content={this.state.ogtitle} />
                    <meta property="og:description" content={this.state.ogdescription} />
                    <meta property="og:url" content={this.state.ogurl} />
                    <meta property="og:site_name" content={this.state.siteName} />
                    <meta property="og:image" content="/assets/img/social/ogimage.png" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content={this.state.twittertitle} />
                    <meta name="twitter:description" content={this.state.twitterdescription} />
                    <meta name="twitter:site" content={this.state.twitterSite} />
                    <meta name="twitter:image" content="/assets/img/social/twitterimage.png" />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default Meta;
