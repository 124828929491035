import React, { Component } from "react";

import { Link } from "react-router-dom";
import DelayLink from "../../../helpers/delayLink";
import { connect } from "react-redux";
import StarRating from "../../../helpers/StarRating";
import { PRODUCT_IMAGE_URL, DEFAULT_IMG  } from "../../../../configs/index";

class FeaturedProducts extends Component {
    constructor(props){
		super(props);
		
	}
	componentWillReceiveProps(nextProps) {
       // console.log(nextProps); 
    }
  
	render() {
		const { products} = this.props;
		
		return (                        
			<React.Fragment>
                         
				<section className="slide-wrapper feature-product">
				  <div className="slide-holder container">
				  		<div className="slider-title">
							<h4 className="title-head" style={{display:"none"}}>Featured Products</h4>
							<div className="title-options"></div>    
						</div>
				
						<div className="row slide-r">
							<div className="col-md-6">
							{(products.map((item, index) =>   item.id === "1" ? 
								<div className="side-img-holder">
								<img className="img-responsive img-fluid" src={PRODUCT_IMAGE_URL + item.product_image} alt={item.product_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
								{/* <img src="/assets/img/featured-img.png"/> */}
								</div>
								:
								""
								))}
							</div>

							<div className="col-md-6">
							{(products.map((item, index) =>   item.id === "1" ? 
								<div className="features-product">

									<h2>Features Products</h2>
									<p>Shop our best products</p>
									<p className="features-tre">{item.product_name}</p>
									<div className="features-prc">
										{(item.discount !=0) ?
											<span><strike>S${item.price}</strike> <span>S${parseFloat(parseFloat(item.price) - (parseFloat(item.price)*(parseInt(item.discount)/100))).toFixed(2) }</span></span>
											:
											<span><span>S${item.price}</span></span>
										}
										<div className="star-rate home-star">
											<StarRating rating = {item.rating} no_of_rating = {0} />
											5.0 
										</div>
									</div>
									<a href={"/productdetails/"+item.id} className="cart-features">View Details</a>
								</div>
							
				

:
							""
							))}
						
						
						<div className="row featured-itemlist" >
						{(products.map((item, index) =>  

						
							<div className="col-6 col-md-4 col-sm-4 item-holder featured-item" key={index}>
								<DelayLink to={"/productdetails/"+item.id} delay={50} >
								<div className="thumb-wrapper">
									<div className="img-box">
									<img className="img-responsive img-fluid" src={PRODUCT_IMAGE_URL + item.product_image} alt={item.product_name} onError={(e)=>{e.target.src=DEFAULT_IMG}} />
									</div>
									<div className="thumb-content">
									<h4 className="item-name">{item.product_name}</h4>
									
									{/* <StarRating rating = {item.rating} no_of_rating = {0} />
										{(item.discount !=0) ?
											<p className="item-price"><strike>S${item.price}</strike> <span>S${parseFloat(parseFloat(item.price) - (parseFloat(item.price)*(parseInt(item.discount)/100))).toFixed(2) }</span></p>
											:
											<p className="item-price"><span>S${item.price}</span></p>
										}
	 */}
									</div>						
								</div>
								</DelayLink>	
							</div>
								
						
						
						))}
						</div>
						</div>
						</div>	
					

				</div>
				</section>
					 
                
            </React.Fragment>
            );
	}
}

const mapStateToProps = state => ({
	
});
export default connect(mapStateToProps, {  })(FeaturedProducts);