import React, { Component } from "react";

class StarRating extends Component {
	    render() {
			const {rating, no_of_rating} = this.props;
			let count = 0;
			var star = '';
			for(var i=0; i<parseInt(rating); i++){
				star +='<li class="list-inline-item"><i class="bi bi-star-fill"></i></li>';		
				count +=1;
			}
			if(parseInt(rating * 2)%2){
				star +='<li class="list-inline-item"><i class="bi bi-star-half"></i></li>';
				count +=1;
			}
			for(var j=count; j<5; j++){
				star +='<li class="list-inline-item"><i class="bi bi-star"></i></li>';
			}
		

        return (
            <React.Fragment>
               <div className="star-rating">
					<ul className="list-inline" dangerouslySetInnerHTML={{__html: star}}>				
					</ul>	
					<div className="no-of-star-rate">{' '} {no_of_rating !='' ? '(' + no_of_rating + ')' : ''}	</div>
				</div>
            </React.Fragment>
        );
    }
}


export default StarRating;

